import type {
  CheckoutDocument,
  GetCheckoutHttpData,
  CreateCheckoutHttpData,
} from '@hitechline/urbanonorte-types/dtos/franchisee/api';
import { ReactNode, useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';

import { CartContext } from '@/engine/contexts/CartContext';
import { api } from '@/modules/services/api';

interface CartProviderProps {
  children: ReactNode;
}

const URBANO_STORE_CART_KEY = '@UrbanoStore:cart';
const isWindow = typeof window !== 'undefined';

export const CartProvider = ({ children }: CartProviderProps): JSX.Element => {
  const [ready, updateReady] = useState(false);
  const [loading, updateLoading] = useState(false);

  const [checkout, updateCheckout] = useState<CheckoutDocument | null>(null);
  const [checkoutId, updateCheckoutId] = useState<string | null>(null);

  const refreshCheckout = useCallback(async () => {
    try {
      const { data } = await api.get<GetCheckoutHttpData>(
        `/checkout/${checkoutId}`,
      );

      updateCheckout(data);
    } catch {
      // nothing
    }
  }, [checkoutId]);

  const loadEncapsulation = useCallback(
    async (callback: () => any) => {
      try {
        updateLoading(true);

        await callback();
        await refreshCheckout();
      } finally {
        updateLoading(false);
      }
    },
    [refreshCheckout],
  );

  const createCheckout = useCallback(async () => {
    try {
      updateCheckoutId(null);

      const { data: createdCheckout } = await api.get<CreateCheckoutHttpData>(
        '/checkout/new',
      );

      updateCheckout(createdCheckout);
      updateCheckoutId(createdCheckout.id);

      localStorage.setItem(URBANO_STORE_CART_KEY, createdCheckout.id);
    } catch {
      // nothing
    }
  }, []);

  const retrieveCheckout = useCallback(
    async (retrieveCheckoutId: string | null) => {
      if (!retrieveCheckoutId) {
        createCheckout();
        return;
      }

      try {
        const { data } = await api.get<GetCheckoutHttpData>(
          `/checkout/${retrieveCheckoutId}`,
        );

        updateCheckout(data);
        updateCheckoutId(data.id);
      } catch {
        localStorage.removeItem(URBANO_STORE_CART_KEY);

        await createCheckout();
      }

      updateReady(true);
    },
    [createCheckout],
  );

  const addProduct = useCallback(
    (productId: string) =>
      loadEncapsulation(async () => {
        await retrieveCheckout(checkoutId);

        try {
          await api.post(`/checkout/${checkoutId}/add`, {
            id: productId,
          });
        } catch {
          toast.error('Erro na adição do produto.');
        }
      }),
    [checkoutId, retrieveCheckout, loadEncapsulation],
  );

  const removeProduct = useCallback(
    (productId: string) =>
      loadEncapsulation(async () => {
        try {
          await api.post(`/checkout/${checkoutId}/remove`, {
            id: productId,
          });
        } catch {
          toast.error('Erro na remoção do produto.');
        }
      }),
    [checkoutId, loadEncapsulation],
  );

  const updateProductQuantity = useCallback(
    (productId: string, quantity: number) =>
      loadEncapsulation(async () => {
        if (quantity < 1) {
          return;
        }

        try {
          await api.post(`/checkout/${checkoutId}/edit`, {
            id: productId,
            quantity,
          });
        } catch {
          toast.error('Erro na alteração de quantidade do produto.');
        }
      }),
    [checkoutId, loadEncapsulation],
  );

  useEffect(() => {
    if (isWindow) {
      const storagedCartId = localStorage.getItem(URBANO_STORE_CART_KEY);

      retrieveCheckout(storagedCartId);
    }
  }, []); // eslint-disable-line

  return (
    <CartContext.Provider
      value={{
        ready,
        loading,
        checkout,
        refreshCheckout,
        addProduct,
        removeProduct,
        updateProductQuantity,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
