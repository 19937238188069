import { logger } from '@hitechline/reactools';
import { AppProps } from 'next/app';
import SwiperCore, { Pagination } from 'swiper';

import { MainProvider } from '@/engine/providers/MainProvider';
import { IS_DEV } from '@/means/data/constants';
import { GoogleAnalytics } from '@/screen/components/common/GoogleAnalytics';
import { Head } from '@/screen/components/common/Head';
import { Mount } from '@/screen/render/Mount';

import '@/screen/styles/global/index.css';

SwiperCore.use([Pagination]);

if (!IS_DEV) {
  logger.disable();
}

const App = ({ pageProps, Component }: AppProps): JSX.Element => (
  <MainProvider>
    <Head />
    <GoogleAnalytics />

    <Mount>
      <Component {...pageProps} />
    </Mount>
  </MainProvider>
);

export default App;
