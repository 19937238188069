import type { CheckoutDocument } from '@hitechline/urbanonorte-types/dtos/franchisee/api';
import { createContext } from 'react';

export interface CartContextData {
  ready: boolean;
  loading: boolean;
  checkout: CheckoutDocument | null;
  refreshCheckout(): any;
  addProduct: (productId: string) => any;
  updateProductQuantity: (productId: string, quantity: number) => any;
  removeProduct: (productId: string) => any;
}

export const CartContext = createContext<CartContextData>(
  {} as CartContextData,
);
