import { createMedia } from '@artsy/fresnel';

type ScreensTypeof = typeof screens;

type BreakPointsKeys = keyof ScreensTypeof;

type BreakPointsType = Record<BreakPointsKeys, number>;

const NUMBER_REGEX = /^(\d+)/;

function getNumber(breakpointValue: string): number {
  const numericCapture = String(breakpointValue).match(NUMBER_REGEX)?.[0];
  const numericValue = numericCapture && Number(numericCapture);

  if (typeof numericValue !== 'number') {
    throw new Error(
      `The breakpoint value "${breakpointValue}" cannot be converted to number.`,
    );
  }

  return numericValue;
}

const screens = {
  'all': '0px',
  'sm': '640px',
  'md': '768px',
  'lg': '1024px',
  'xl': '1280px',
  '2xl': '1536px',
} as const;

const breakpoints: BreakPointsType = Object.keys(
  screens,
).reduce<BreakPointsType>(
  (breakpointsObject, breakpointKey): BreakPointsType => ({
    ...breakpointsObject,
    [breakpointKey]: getNumber(screens[breakpointKey]),
  }),
  {} as BreakPointsType,
);

export const { Media, MediaContextProvider } = createMedia({
  breakpoints,
});
