import { createContext, ReactPortal } from 'react';

export interface PortalContextData {
  render(
    node: JSX.Element,
    elementId: typeof elements[number],
  ): ReactPortal | JSX.Element;
  getElement(
    elementId: typeof elements[number],
  ): HTMLDivElement | null | undefined;
}

export const elements = ['menus', 'modals'] as const;
const defaultContextData = {} as PortalContextData;

export const PortalContext =
  createContext<PortalContextData>(defaultContextData);
