import { BlockUIStyle } from '@/screen/styles/BlockUIStyle';

import { Spinner } from '../Spinner';
import styles from './styles.module.css';

export const StandardLoader = (): JSX.Element => (
  <>
    <BlockUIStyle />

    <div className={styles.container}>
      <Spinner className="w-16 h-16" />
    </div>
  </>
);
