import { ReactNode } from 'react';

import { MediaContextProvider } from './Media';

export interface MountProps {
  children: ReactNode;
}

export function Mount({ children }: MountProps): JSX.Element {
  return (
    <MediaContextProvider>
      <div id="mount">
        <div className="flex w-full min-h-screen flex-col justify-between">
          {children}
        </div>
      </div>
    </MediaContextProvider>
  );
}
