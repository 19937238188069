import { mergeClassNames } from '@hitechline/reactools';
import type { HTMLAttributes } from 'react';

import styles from './styles.module.css';

interface Props<T extends string = string>
  extends Omit<HTMLAttributes<HTMLDivElement>, 'className'> {
  color?: string;
  className:
    | `w-${T} h-${T}`
    | `h-${T} w-${T}`
    | `h-${T} w-${T}${string}`
    | `w-${T} h-${T}${string}`;
}

export const Spinner = ({
  className,
  color = 'floor-green',
  ...props
}: Props): JSX.Element => (
  <div
    {...props}
    className={mergeClassNames(
      styles.container,
      className,
      `border-t-${color}`,
      'animate-spin border-4 flex-shrink-0 rounded-full',
    )}
  />
);
