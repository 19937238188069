import {
  FranchiseeDocument,
  SessionLoginData,
} from '@hitechline/urbanonorte-types/dtos/franchisee/api';
import { createContext } from 'react';

export type SignInData = Pick<SessionLoginData, 'email' | 'password'>;

export interface SignInReturnData {
  success: boolean;
  error?: string;
}

export interface AuthContextData {
  ready: boolean;
  isAuthenticated: boolean;
  user: FranchiseeDocument | null;
  logOut(): void;
  autoLogin(token: string): Promise<boolean>;
  signIn: (data: SignInData) => Promise<SignInReturnData>;
}

export const AuthContext = createContext({} as AuthContextData);
