import axios, { AxiosInstance } from 'axios';

import { getAuthToken } from '../auth/getAuthToken';

export const api = makeAPI();

function makeAPI(): AxiosInstance {
  const instance = axios.create({
    baseURL: process.env.NEXT_PUBLIC_API_URL,
  });

  instance.interceptors.request.use(config => {
    const token = getAuthToken();

    if (token) {
      config.headers = Object.assign(config.headers ?? {}, {
        Authorization: `Bearer ${token}`,
      });
    }

    return config;
  });

  return instance;
}

/* eslint no-param-reassign: off */
