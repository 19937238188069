import {
  FranchiseeDocument,
  VerifySessionLoginHttpData,
} from '@hitechline/urbanonorte-types/dtos/franchisee/api';
import { useEffect, useState, ReactNode } from 'react';

import {
  AuthContext,
  SignInData,
  SignInReturnData,
} from '@/engine/contexts/AuthContext';
import { getAuthToken } from '@/modules/auth/getAuthToken';
import { removeAuthToken } from '@/modules/auth/removeAuthToken';
import { setAuthToken } from '@/modules/auth/setAuthToken';
import { api } from '@/modules/services/api';
import { submitLogin } from '@/modules/submit/login';

export interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
  const [ready, updateReady] = useState<boolean>(false);
  const [isAuthenticated, updateIsAuthenticated] = useState<boolean>(false);

  const [user, updateUser] = useState<FranchiseeDocument | null>(null);

  function logOut(): void {
    removeAuthToken();
    updateUser(null);
    updateIsAuthenticated(false);
  }

  async function retrieve(): Promise<void> {
    const token = getAuthToken();

    if (!token) {
      updateReady(true);
      removeAuthToken();
      return;
    }

    const isValidToken = await verifyToken(token);

    if (!isValidToken) {
      updateReady(true);
      removeAuthToken();
      return;
    }

    try {
      const { data: userData } = await api.get<FranchiseeDocument>('/me');

      updateUser(userData);
      updateIsAuthenticated(true);
    } catch {
      removeAuthToken();
    } finally {
      updateReady(true);
    }
  }

  async function autoLogin(token: string): Promise<boolean> {
    const isValidToken = await verifyToken(token);

    if (!isValidToken) {
      return false;
    }

    try {
      const { data: userData } = await api.get<FranchiseeDocument>('/me', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setAuthToken(token);
      updateIsAuthenticated(true);
      updateUser(userData);

      return true;
    } catch {
      return false;
    }
  }

  async function verifyToken(token: string): Promise<boolean> {
    try {
      const { data } = await api.get<VerifySessionLoginHttpData>(
        '/sessions/login/verify',
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      return data.ok;
    } catch {
      return false;
    }
  }

  async function signIn(data: SignInData): Promise<SignInReturnData> {
    const { email, password } = data;
    const { error, data: submittedData } = await submitLogin({
      email,
      password,
      stay: false,
    });

    if (error || !submittedData) {
      return {
        success: false,
        error: error ?? 'Ocorreu um erro, tente novamente.',
      };
    }

    if (submittedData.type !== 1) {
      return {
        success: false,
        error: 'Você não pode fazer login na plataforma.',
      };
    }

    setAuthToken(submittedData.token);
    updateIsAuthenticated(true);
    updateUser(submittedData.user as FranchiseeDocument);

    return {
      success: true,
    };
  }

  useEffect(() => {
    retrieve();
  }, []); // eslint-disable-line

  return (
    <AuthContext.Provider
      value={{ ready, isAuthenticated, user, logOut, autoLogin, signIn }}
    >
      {children}
    </AuthContext.Provider>
  );
};
