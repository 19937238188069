import { useCallback, useMemo, useState, useEffect, ReactNode } from 'react';

import { UIContext } from '@/engine/contexts/UIContext';
import { StandardLoader } from '@/screen/components/ui/StandardLoader';

export interface UIProviderProps {
  children: ReactNode;
}

export function UIProvider({ children }: UIProviderProps): JSX.Element {
  const [ready, updateReady] = useState(false);
  const [loading, updateLoading] = useState(false);

  const setLoading = useCallback((isLoading: boolean) => {
    updateLoading(Boolean(isLoading));
  }, []);

  const contextValue = useMemo(
    () => ({
      ready,
      setLoading,
    }),
    [ready, setLoading],
  );

  useEffect(() => {
    updateReady(true);
  }, []);

  return (
    <UIContext.Provider value={contextValue}>
      {loading && <StandardLoader />}

      {children}
    </UIContext.Provider>
  );
}
