import { setCookie } from 'nookies';

import { AUTH_TOKEN_KEY } from './constants';

export function setAuthToken(token: string): void {
  setCookie(undefined, AUTH_TOKEN_KEY, token, {
    path: '/',
    maxAge: 60 * 60 * 8,
  });
}
