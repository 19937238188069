export const IS_DEV = process.env.NODE_ENV !== 'production';

//

export const GA_TRACKING_ID = '';

//

export const APP_URL = 'https://loja.urbanonorte.app';
export const APP_PUBLIC_URL = IS_DEV ? process.env.PUBLIC_URL ?? '/' : APP_URL;

//

export const EMAIL_URL = 'mailto:contato@urbanonorte.app';
export const INSTAGRAM_URL = 'https://www.instagram.com';
