import {
  PasswordError,
  RegisterNotFoundError,
  ErrorObject,
} from '@hitechline/urbanonorte-errors';
import type {
  SessionLoginData,
  CreateSessionLoginHttpData,
} from '@hitechline/urbanonorte-types/dtos/franchisee/api';
import type { AxiosError } from 'axios';

import { api } from '@/modules/services/api';

import type { SubmittedReturnData } from '../types/submit';

const defaultError = {
  data: null,
  error: 'Ocorreu um erro inespereado, tente novamente!',
};

export async function submitLogin(
  data: SessionLoginData,
): Promise<SubmittedReturnData<CreateSessionLoginHttpData, string>> {
  try {
    const { data: loginData } = await api.post<CreateSessionLoginHttpData>(
      '/sessions/login',
      data,
    );

    return {
      error: null,
      data: loginData,
    };
  } catch (error) {
    const { response } = error as AxiosError<ErrorObject>;

    if (!response) {
      return defaultError;
    }

    const { code } = response.data;

    if (typeof code !== 'number') {
      return defaultError;
    }

    switch (code) {
      case RegisterNotFoundError.code:
        return {
          data: null,
          error: 'Nenhum registro encontrado, crie sua conta.',
        };
      case PasswordError.code:
        return {
          data: null,
          error: 'A senha inserida está incorreta.',
        };
      default:
        return defaultError;
    }
  }
}
