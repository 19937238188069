import 'react-toastify/dist/ReactToastify.css';

import { ReactNode } from 'react';
import { ToastContainer } from 'react-toastify';

import { AuthProvider } from './AuthProvider';
import { CartProvider } from './CartProvider';
import { PortalProvider } from './PortalProvider';
import { UIProvider } from './UIProvider';

export interface MainProviderProps {
  children: ReactNode;
}

export function MainProvider({ children }: MainProviderProps): JSX.Element {
  return (
    <>
      <ToastContainer autoClose={3000} />

      <PortalProvider>
        <AuthProvider>
          <CartProvider>
            <UIProvider>{children}</UIProvider>
          </CartProvider>
        </AuthProvider>
      </PortalProvider>
    </>
  );
}
