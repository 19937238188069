import type { IncomingMessage } from 'http';

import { parseCookies } from 'nookies';

import { AUTH_TOKEN_KEY } from './constants';

let reqToken: string | null = null;

export function getAuthToken(): string | null {
  const cookies = parseCookies();

  return cookies[AUTH_TOKEN_KEY] ?? reqToken ?? null;
}

export function setReqAuthToken(req: IncomingMessage): void {
  const cookies = parseCookies({ req });

  reqToken = cookies[AUTH_TOKEN_KEY] ?? null;
}
